// #dailySalesReportId td:nth-child(7),
// #dailySalesReportId td:nth-child(8),
// #dailySalesReportId td:nth-child(9)
#dailySalesReportId td:nth-child(10)
{
    text-align: right;
};
#dailySalesReportId td:nth-child(11)
{
    text-align: right;
};
#dailySalesReportId td:nth-child(9)
{
    text-align: right;
};
#dailySalesReportId td:nth-child(8)
{
    text-align: right;
};
#rupee.a {list-style-type: "₹"}